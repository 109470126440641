import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
  ExternalLink
} from './bannerSection.style';

import HomeView from 'common/src/assets/image/saas/agencee_home.png';
import SearchView from 'common/src/assets/image/saas/agencee-search.png';
import GenContent from 'common/src/assets/image/saas/agencee-gen-content.png';

const AgenceeSection = ({
  row,
  col,
  title,
  description,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Agencee"
                  {...title}
                />
              }
              description={
                <div>
                  <Text
                    content="Agencee enables personalisation at scale by helping copywriters and creative teams to keep up with demand for content variations to support more experiments and smaller niches. The ultimate goal is to enable true one-to-one personalisation."
                    {...description}
                  />
                  <Text
                    content="The use of Generative AI has grown to support much more than content generation. Generative AI is used to streamline reviews by catching errors before deployment, to analyse content in batch, replacing previously specialised NLP models and pipelines, and to perform live monitoring of deployed content using multi-modal capabilities."
                    {...description}
                  />
                  <Text/>
                  <Image src={SearchView} alt="Semantic Search" />
                  <Image src={GenContent} alt="Generating content and variations" />
                </div>
              }
            />
          </Box>
        </Box>
      </Container>
      <BannerObject style={{ alignItems: 'flex-start', top: 157}}>
        <div className="objectWrapper">
          <Image src={HomeView} alt="Agencee Home" />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

AgenceeSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

AgenceeSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
};

export default AgenceeSection;
